import React from 'react'
import cn from 'classnames'

import Cart from 'components/Cart/Cart'
import { LogoVariants } from 'components/Logotype/LogoImage'
import Logotype from 'components/Logotype/Logotype'
import MarketSwitcher from 'components/MarketSwitcher/MarketSwitcher'
import NoSSR from 'components/NoSSR'
import SideNavButton from 'components/SideNavButton/SideNavButton'
import TrustBanner from 'components/TrustBanner/TrustBanner'
import Wrapper from 'components/Wrapper/Wrapper'
import usePath from 'hooks/usePath'
import useResponsive from 'hooks/useResponsive'

import BusinessButton from './BusinessButton'
import SearchButton from './SearchButton'

import styles from './NavigationBar.module.css'

type NavigationBarProps = {
  hideSearchButton?: boolean
  logoVariant?: string | undefined
  headerBorder?: boolean
  redBackground?: boolean
  greyBackground?: boolean
  darkContent?: boolean
}

const NavigationBar: React.FC<NavigationBarProps> = ({
  hideSearchButton = false,
  logoVariant = undefined,
  headerBorder = false,
  redBackground = false,
  greyBackground = false,
  darkContent = false,
}) => {
  const { isMobile } = useResponsive()
  const { nakedPath } = usePath()
  const isHome = !nakedPath

  // eslint-disable-next-line no-param-reassign
  if (!logoVariant) logoVariant = darkContent ? 'dark' : 'light'

  return (
    <div
      className={cn(styles.base, {
        [styles.headerBorder]: headerBorder,
        [styles.baseRedBackground]: redBackground,
        [styles.headerWithBanner]: isHome,
        [styles.greyBackground]: greyBackground,
      })}
      data-cy="navigationBar"
    >
      {isHome && <TrustBanner />}
      <Wrapper className={styles.wrapper}>
        <div className={styles.innerWrapper}>
          <nav className={styles.topNav}>
            <SideNavButton dark={darkContent} />

            <div className={styles.logoContainer}>
              <Logotype
                size={isMobile ? 'sm' : 'md'}
                variant={logoVariant as LogoVariants}
              />
            </div>

            <div className={styles.topNavFunctions}>
              {!hideSearchButton && <SearchButton dark={darkContent} />}
              <BusinessButton dark={darkContent} />
              <MarketSwitcher />
              <NoSSR>
                <Cart dark={darkContent} />
              </NoSSR>
            </div>
          </nav>
        </div>
      </Wrapper>
    </div>
  )
}

export default NavigationBar
