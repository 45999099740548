import React from 'react'

import NavigationBar from 'components/NavigationBar/NavigationBar'
import StartPageHero from 'components/StartPageHero/StartPageHero'
import useFeatureFlags from 'hooks/useFeatureFlags'

import styles from './PageHeaderStatic.module.css'

type PageHeaderStaticProps = {
  headerContent: React.ReactNode
  navigationBarDarkContent?: boolean
  headerRef: React.MutableRefObject<HTMLDivElement>
}

const PageHeaderStatic: React.FC<PageHeaderStaticProps> = ({
  headerContent,
  navigationBarDarkContent,
  headerRef,
}) => {
  const { cmsHero } = useFeatureFlags()

  return (
    <header ref={headerRef} className={styles.header}>
      <NavigationBar darkContent={navigationBarDarkContent} />
      {!cmsHero && (
        <div className={styles.headerContent}>
          {headerContent && <StartPageHero />}
        </div>
      )}
    </header>
  )
}

export default PageHeaderStatic
